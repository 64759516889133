html {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    text-rendering: optimizeLegibility;
    text-transform: none !important;
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: auto;
}

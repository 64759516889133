.StandardCircular {
    max-width: 1080px;
    overflow: inherit !important;
    margin: 0 0 10px 0 !important;
    padding: 4px 4px 6px 4px !important;

    .MuiImageListItem-root {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #172b614d;
        border-radius: 4px;

        img {
            max-height: 100% !important;
            transform: none !important;
            top: 0 !important;
            left: 0 !important;
            object-fit: cover;
            user-select: none;
            border-radius: 4px;
        }

        .header-tile-bar {
            display: flex;
            height: 40px !important;
            background: #ffffff00 !important;

            .MuiImageListItemBar-titleWrap {
                flex: 0 0 auto;

                .MuiImageListItemBar-title {
                    color: #333333 !important;
                    font-size: 20px;
                    font-weight: 800;
                    text-shadow: -1.5px 1px 0 #ffffff99, 1.5px 1px 0 #ffffff99,
                        1.5px -1px 0 #ffffff99, -1.5px -1px 0 #ffffff99;
                }
            }

            .MuiImageListItemBar-actionIcon {
                display: flex;
                flex: 1 1 auto;
                justify-content: flex-end;

                div {
                    color: #333333 !important;
                    font-size: 11px;
                    font-weight: 800;
                    margin: 0 10px;
                    text-align: right;
                    text-shadow: -1.5px 1px 0 #ffffff99, 1.5px 1px 0 #ffffff99,
                        1.5px -1px 0 #ffffff99, -1.5px -1px 0 #ffffff99;
                }
            }
        }

        .footer-tile-bar {
            height: 60px !important;
            background: #ffffff99 !important;
            box-shadow: 0 0 20px 0 #ffffff99;

            .MuiImageListItemBar-titleWrap {
                .MuiImageListItemBar-title {
                    color: #333333 !important;
                    font-size: 14px;
                    font-weight: 600;
                }

                .MuiImageListItemBar-subtitle {
                    color: #333333 !important;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .MuiImageListItemBar-actionIcon {
                .add-action-button {
                    svg {
                        width: 32px;
                        height: 32px;
                        color: #f67a48;
                    }
                }

                .added-indicator {
                    svg {
                        width: 32px;
                        height: 32px;
                        color: #0b9790;
                    }
                }
            }
        }
    }
}

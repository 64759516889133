.LoadingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-indicator-label {
        font-weight: 300;
        margin-right: 15px;
        user-select: none;
        color: #333333 !important;

        &.loading-indicator-label-small {
            font-size: 18px;
        }

        &.loading-indicator-label-medium {
            font-size: 20px;
        }

        &.loading-indicator-label-large {
            font-size: 26px;
        }
    }

    .loading-indicator {
        color: #5cb8b5 !important;
    }
}

$item-gap: 4px;

.Circular {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;

    .loading-indicator-container {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.Header {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: 100%;
    max-width: 1080px;
    color: #333333;

    .main-logo-container {
        flex: 0 0 auto;
        padding: 10px;

        .main-logo {
            min-height: 100px;
            max-height: 180px;
            max-width: 220px;
        }
    }

    .circular-info {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 20px;

        .location-info-container {
            display: flex;
            flex-direction: row;
            flex: 1 1 auto;

            .column-spacer {
                flex: 1 1 auto;
            }

            div:nth-child(2) {
                display: flex;
                flex-direction: column;
                flex: 0 0 auto;
                justify-content: flex-start;
                font-size: 15px;

                .open-map-button {
                    padding: 0 !important;
                    border-radius: 0 !important;
                    text-transform: none !important;
                    justify-content: flex-start !important;
                    font-weight: 700;
                    color: #333333;

                    &:hover {
                        background-color: #ffffff00;
                    }
                }
            }
        }

        .circular-dates-container {
            display: flex;
            flex-direction: row;
            flex: 1 1 auto;

            .column-spacer {
                flex: 1 1 auto;
            }

            div:nth-child(2) {
                display: flex;
                flex-direction: column;

                .row-spacer {
                    flex: 1 1 auto;
                }

                div:nth-child(2) {
                    display: flex;
                    flex-direction: row;
                    flex: 0 0 auto;
                    font-size: 15px;

                    div:nth-child(1) {
                        font-weight: 700;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .Header {
        .main-logo-container {
            .main-logo {
                max-height: 125px;
            }
        }

        .circular-info {
            .location-info-container {
                div:nth-child(2) {
                    font-size: 12px;
                }
            }

            .circular-dates-container {
                div:nth-child(2) {
                    div:nth-child(2) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
